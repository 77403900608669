// Bootstrap functions & mixins imported before custom variables
@import "../../lib/bootstrap/scss/functions";
@import "./variables";  // Uncomment variables as needed, modify their values, and remove the !default flag.
@import "../../lib/bootstrap/scss/mixins";

// Libraries vendor
// ==========================================================
// jquery-ui.custom - manually reduced code to be minimal
//@import "../../lib/jquery-ui-1.12.1.custom/jquery-ui.custom";
//@import "../../lib/semantic-ui/sticky/sticky";
//@import "../../lib/mmenu/jquery.mmenu-modified.all";
//@import "../../lib/bootstrap/scss/bootstrap";

// Bootstrap imports by each part separately - it is recommended not to modify base files
// Bootstrap functions & mixins imported above custom variables
// Toasts, carousel (replaced with swiper) & spinners commented - uncomment here & js index when enabled
//@import "../../lib/bootstrap/scss/bootstrap";
//@import "../../lib/bootstrap/scss/functions";
@import "../../lib/bootstrap/scss/variables";
//@import "../../lib/bootstrap/scss/mixins";
@import "../../lib/bootstrap/scss/root";
@import "../../lib/bootstrap/scss/reboot";
@import "../../lib/bootstrap/scss/type";
@import "../../lib/bootstrap/scss/images";
@import "../../lib/bootstrap/scss/code";
@import "../../lib/bootstrap/scss/grid";
@import "../../lib/bootstrap/scss/tables";
@import "../../lib/bootstrap/scss/forms";
@import "../../lib/bootstrap/scss/buttons";
@import "../../lib/bootstrap/scss/transitions";
@import "../../lib/bootstrap/scss/dropdown";
@import "../../lib/bootstrap/scss/button-group";
@import "../../lib/bootstrap/scss/input-group";
@import "../../lib/bootstrap/scss/custom-forms";
@import "../../lib/bootstrap/scss/nav";
@import "../../lib/bootstrap/scss/navbar";
@import "../../lib/bootstrap/scss/card";
@import "../../lib/bootstrap/scss/breadcrumb";
@import "../../lib/bootstrap/scss/pagination";
@import "../../lib/bootstrap/scss/badge";
@import "../../lib/bootstrap/scss/jumbotron";
@import "../../lib/bootstrap/scss/alert";
@import "../../lib/bootstrap/scss/progress";
@import "../../lib/bootstrap/scss/media";
@import "../../lib/bootstrap/scss/list-group";
@import "../../lib/bootstrap/scss/close";
//@import "../../lib/bootstrap/scss/toasts";
@import "../../lib/bootstrap/scss/modal";
@import "../../lib/bootstrap/scss/tooltip";
@import "../../lib/bootstrap/scss/popover";
//@import "../../lib/bootstrap/scss/carousel";
@import "../../lib/bootstrap/scss/spinners";
@import "../../lib/bootstrap/scss/utilities";
@import "../../lib/bootstrap/scss/print";

.btn-sort {
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 14px 14px;
	color: $gray-900;
	font-weight: 700;
	padding-left: 0;
	padding-right: 1.25em;

	&:hover {
		color: $gray-900;
		text-decoration: none;
	}

	&-down {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiMwMDdiZmYiIGlkPSJzdmdfMSIgZD0ibTk4LjksMTg0LjdsMS44LDIuMWwxMzYsMTU2LjVjNC42LDUuMyAxMS41LDguNiAxOS4yLDguNmM3LjcsMCAxNC42LC0zLjQgMTkuMiwtOC42bDEzNS45LC0xNTYuMmwyLjMsLTIuNmMxLjcsLTIuNSAyLjcsLTUuNSAyLjcsLTguN2MwLC04LjcgLTcuNCwtMTUuOCAtMTYuNiwtMTUuOGwwLDBsLTI4Ni44LDBsMCwwYy05LjIsMCAtMTYuNiw3LjEgLTE2LjYsMTUuOGMwLDMuMyAxLjEsNi40IDIuOSw4Ljl6Ii8+IDwvZz48L3N2Zz4=);
	}
	&-up {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiMwMDdiZmYiIGlkPSJzdmdfMSIgZD0ibTQxMy4xLDMyNy4zbC0xLjgsLTIuMWwtMTM2LC0xNTYuNWMtNC42LC01LjMgLTExLjUsLTguNiAtMTkuMiwtOC42Yy03LjcsMCAtMTQuNiwzLjQgLTE5LjIsOC42bC0xMzUuOSwxNTYuMmwtMi4zLDIuNmMtMS43LDIuNSAtMi43LDUuNSAtMi43LDguN2MwLDguNyA3LjQsMTUuOCAxNi42LDE1LjhsMCwwbDI4Ni44LDBsMCwwYzkuMiwwIDE2LjYsLTcuMSAxNi42LC0xNS44YzAsLTMuMyAtMS4xLC02LjQgLTIuOSwtOC45eiIvPiA8L2c+PC9zdmc+);
	}
	&-inactive {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MS45OTk5OTk5OTk5OTk4IiBoZWlnaHQ9IjE3OTEuOTk5OTk5OTk5OTk5OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiMwMDdiZmYiIGlkPSJzdmdfMSIgZD0ibTE0MDgsMTA4OHEwLDI2IC0xOSw0NWwtNDQ4LDQ0OHEtMTksMTkgLTQ1LDE5dC00NSwtMTlsLTQ0OCwtNDQ4cS0xOSwtMTkgLTE5LC00NXQxOSwtNDV0NDUsLTE5bDg5NiwwcTI2LDAgNDUsMTl0MTksNDV6bTAsLTM4NHEwLDI2IC0xOSw0NXQtNDUsMTlsLTg5NiwwcS0yNiwwIC00NSwtMTl0LTE5LC00NXQxOSwtNDVsNDQ4LC00NDhxMTksLTE5IDQ1LC0xOXQ0NSwxOWw0NDgsNDQ4cTE5LDE5IDE5LDQ1eiIvPiA8L2c+PC9zdmc+);
	}
}

// Fontawesome installed as npm package
// Only regular version in use as default - uncomment for fal / fas / fab icons
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
//@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
/*

// Custom Libraries
// ==========================================================
@import "../../lib/ripple-wave/ripple-wave";
@import "../../lib/read-more/read-more";
@import "../../lib/sticky-footer/sticky-footer";
@import "../../lib/divider/divider";

// Utilities
// ==========================================================
@import "./utilities/fonts";
@import "./utilities/utilities";
@import "./utilities/animations";
@import "./utilities/borders";
@import "./utilities/responsive-sizes";
@import "./utilities/positions";
@import "./utilities/font-sizes";

// Components
// ==========================================================
@import "./components/general";
@import "./components/header";
@import "./components/footer";
@import "./components/loader";
@import "./components/overlay-loader";
@import "./components/accordion";
@import "./components/mega-dropdown";
@import "./components/category-sidebar";
@import "./components/swiper-slider";
@import "./components/instagram";

// Product
@import "./components/product/product-general";
@import "./components/product/product-card";
@import "./components/product/promo-label";
@import "./components/product/product-cta";

// Cart
@import "./components/cart/nav-cart";
@import "./components/cart/cart-summary";
@import "./components/cart/cart";
@import "./components/cart/paytrail-payment-list";
@import "./components/cart/epayment-form";

// Order
@import "./components/order-history/order-info";
@import "./components/order-history/order-status-label";

// Profile
@import "./components/profile/profile";

// Watchlist
@import "./components/watchlist/watchlist";
*/